import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

@Injectable({
    providedIn: 'root',
})
export class AppContextService {
    context = {
        vp: {},
        vollmacht: {
            anrede: null,
            anzahl_erinnerungen: null,
            buro_id: null,
            c_o: null,
            created_at: null,
            email: null,
            einwilligungen: null,
            geburtsdatum: null,
            id: null,
            key: null,
            key_valid_until: null,
            kundennummer: null,
            land: null,
            mobil: null,
            name: null,
            ort: null,
            plz: null,
            strasse: null,
            titel: null,
            ts_abgelehnt: null,
            ts_finished: null,
            ts_lastlogin: null,
            ts_letzte_erinnerung: null,
            ts_letzter_export: null,
            unterschrift: null,
            unterschrift_vm: null,
            updated_at: null,
            user_id: null,
            vollmachten: {
                maklervertrag: null,
                vertragsbestand: {
                    vertraege: [],
                },
                datenschutz: {
                    kommunikation: {
                        brief: true,
                        telefon: false,
                        fax: false,
                        email: false,
                        whatsapp: false,
                    },
                    beratung_weitere_produkte: false,
                    datenweitergabe_an_personen: null,
                },
                frei: {
                    title: null,
                    description: null,
                    template: null,
                    attachment: null,
                },
            },
            vollmachtstypen: null,
            vorname: null,
        },
        alreadySigned: false,
        allSigned: false,
        organisationData: null,
        config: null,
        key: null,
        dynamicform: null,
        models: null,
        consent: null,
        customizing: {
            styling: null,
            logo: {
                name: null,
                mime: null,
                data: null,
            },
            logo_height: null,
        },
        model_infos: null,
        persona: null,
        personas: null,
        persona_tmp: null,
        legal_forms: null,
        persona_individual: null,
        persona_legal_entity: null,
        email_pattern: null,
        sign_process: null,
        state_sign_mode: null,
        state_sign_next_pass: null,
        headline: null,
        isPlatformDesktop: false,
        isPlatformMobile: false,
        isLoggedIn: false,
        isStammdatenVisited: false,
        isBuergschaftsvertragVisited: false,
        isVermittlungsvertragVisited: false,
        isWerbeeinwilligungVisited: false,
        isDiskontcourtageantragVisited: false,
        isDatenschutzbeauftragterVisited: false,
        isIdentifizierungsnachweis_natpersonenVisited: false,
        isIdentifizierungsnachweis_jurpersonenVisited: false,
        isErweiterungb196Visited: false,
        isDynamicformVisited: false,
        isdkmVisited: false,
        isstandbuchungVisited: false,
        isBankdatenVisited: false,
        isUmfangVisited: false,
        isKontaktVisited: false,
        isBestandsaufnahmeVisited: false,
        isDatenschutzVisited: false,
        isFreiVisited: false,
        isZusammenfassungVisited: false,
        isIduploadVisited: false,
        isFileUploadVisited: false,
        isEinwilligungVisited: false,
        isEinwilligungErstinfoVisited: false,
        isUnterschriftVisited: false,

        privatDisplayString: '',
        berufDisplayString: '',
        investmentDisplayString: '',
        kommunikationDisplayString: '',
        umfangDisplayString: '',
        kontaktDisplayString: '',
        buergschaftsvertragDisplayString: '',
        vermittlungsvertragDisplayString: '',
        werbeeinwilligungBlock1DisplayString: '',
        werbeeinwilligungBlock2DisplayString: '',
        werbeeinwilligungBlock3DisplayString: '',
        werbeeinwilligungBlock4DisplayString: '',
        diskontcourtageantragBlock1DisplayString: '',
        diskontcourtageantragBlock2DisplayString: '',
        diskontcourtageantragBlock3DisplayString: '',
        identifizierungsnachweis_natpersonenBlock1DisplayString: '',
        identifizierungsnachweis_natpersonenBlock2DisplayString: '',
        identifizierungsnachweis_natpersonenBlock3DisplayString: '',
        identifizierungsnachweis_natpersonenBlock4DisplayString: '',
        identifizierungsnachweis_jurpersonenBlock1DisplayString: '',
        identifizierungsnachweis_jurpersonenBlock2DisplayString: '',
    };

    cfgBottomDrawer = {
        show: true,
        start: {
            show: false,
            index: 0,
        },
        stammdaten: {
            show: false,
            index: 0,
        },
        vermittlungsvertrag: {
            show: false,
            index: 0,
        },
        datenschutzbeauftragter: {
            show: false,
            index: 0,
        },
        identifizierungsnachweis_natpersonen: {
            show: false,
            index: 0,
        },
        identifizierungsnachweis_jurpersonen: {
            show: false,
            index: 0,
        },
        diskontcourtageantrag: {
            show: false,
            index: 0,
        },
        buergschaftsvertrag: {
            show: false,
            index: 0,
        },
        werbeeinwilligung: {
            show: false,
            index: 0,
        },
        erweiterungb196: {
            show: false,
            index: 0,
        },
        dynamicform: {
            show: false,
            index: 0,
        },
        dkm: {
            show: false,
            index: 0,
        },
        standbuchung: {
            show: false,
            index: 0,
        },
        bankdaten: {
            show: false,
            index: 0,
        },
        umfang: {
            show: false,
            index: 0,
        },
        kontakt: {
            show: false,
            index: 0,
        },
        bestandsaufnahme: {
            show: false,
            index: 0,
        },
        datenschutz: {
            show: false,
            index: 0,
        },
        frei: {
            show: false,
            index: 0,
        },
        einwilligungerstinfo: {
            show: false,
            index: 0,
        },
        zusammenfassung: {
            show: false,
            index: 0,
        },
        idupload: {
            show: false,
            index: 0,
        },
        fileupload: {
            show: false,
            index: 0,
        },
        einwilligung: {
            show: false,
            index: 0,
        },
        unterschrift: {
            show: false,
            index: 0,
        },
    };

    constructor(public platform: Platform) {
        this.context.isPlatformDesktop = this.platform.is('desktop');
        this.context.isPlatformMobile = this.platform.is('mobileweb') || this.platform.is('mobile');
    }

    initBottomDrawer() {
        const models = this.context.models;

        if (this.context.organisationData.name === 'BCA') {
            var pages = Array('start', 'fileupload', 'einwilligung', 'unterschrift');
        } else {
            var pages = Array('start', 'einwilligung', 'unterschrift');
        }

        for (let key in models) {
            if (key == 'dynamic') {
                pages = pages.concat(['dynamicform']);
            }

            if (key == 'einwilligungerstinfo') {
                pages = pages.concat(['einwilligungerstinfo', 'zusammenfassung']);
            }

            if (key == 'erwb196') {
                pages = pages.concat(['erweiterungb196']);
            }

            if (key == 'dkm') {
                pages = pages.concat(['dkm']);
            }

            if (key == 'standbuchung') {
                pages = pages.concat(['standbuchung']);
            }

            if (key == 'idupload') {
                pages = pages.concat(['idupload']);
            }

            if (key == 'md') {
                pages = pages.concat(['stammdaten', 'zusammenfassung']);
            }

            if (key == 'vermittver') {
                pages = pages.concat(['vermittlungsvertrag', 'zusammenfassung']);
            }

            if (key == 'daschu') {
                pages = pages.concat(['datenschutzbeauftragter', 'zusammenfassung']);
            }

            if (key == 'idnp') {
                pages = pages.concat(['identifizierungsnachweis_natpersonen', 'zusammenfassung']);
            }

            if (key == 'idjp') {
                pages = pages.concat(['identifizierungsnachweis_jurpersonen', 'zusammenfassung']);
            }

            if (key == 'diskont') {
                pages = pages.concat(['diskontcourtageantrag', 'zusammenfassung']);
            }

            if (key == 'bsv') {
                pages = pages.concat(['buergschaftsvertrag', 'zusammenfassung']);
            }

            if (key == 'werbeeinw') {
                pages = pages.concat(['werbeeinwilligung', 'zusammenfassung']);
            }

            if (key == 'bue') pages = pages.concat(['bestandsaufnahme', 'zusammenfassung']);

            if (key == 'umfang') {
                pages = pages.concat(['umfang', 'zusammenfassung']);
            }
            if (key == 'kontakt') {
                pages = pages.concat(['kontakt', 'zusammenfassung']);
            }
            if (key == 'dkomm') {
                if (this.context.models.dkomm) pages = pages.concat(['stammdaten', 'datenschutz', 'zusammenfassung']);
            }
        }

        var index = 0;
        for (let key in this.cfgBottomDrawer) {
            if (pages.indexOf(key) > -1) {
                if (this.context.alreadySigned && key == 'unterschrift') {
                    this.cfgBottomDrawer[key].show = true;
                    this.cfgBottomDrawer[key].index = 1;
                }
                if (!this.context.alreadySigned) {
                    this.cfgBottomDrawer[key].show = true;
                    this.cfgBottomDrawer[key].index = index;
                    index++;
                }
            }
        }
        //console.log('pages', pages);
    }

    getNextPage(actual: string) {
        // console.log(this.cfgBottomDrawer);
        // console.log('Actual', actual);
        var found = false;
        for (let key in this.cfgBottomDrawer) {
            if (key == actual) {
                found = true;
            } else {
                if (found && this.cfgBottomDrawer[key].show) {
                    //console.log('next', key);
                    return key;
                }
            }
        }
        return null;
    }

    getPrevPage(actual: string) {
        //console.log(this.cfgBottomDrawer);
        console.log('Actual: ', actual);

        var found = false;
        var prev = null;

        for (let key in this.cfgBottomDrawer) {
            if (this.cfgBottomDrawer[key].show) {
                if (key == actual) {
                    return prev;
                }

                prev = key;
            }
        }
        return null;
    }
}
